<template>
  <div class="home">
    <h3 class="common-title">商品列表</h3>
    <div class="clearfix pb20">
      <div class="fl pb5 pr5 pt5">
        <el-input type="text" v-model="goodsListFilter.keyword" placeholder="请输入商品名称、详情或jancode" clearable
                  @keyup.enter="getGoodsList" style="min-width: 300px"></el-input>
      </div>
      <!--      <div class="fl pb5 pr5 pt5">-->
      <!--        <el-select-->
      <!--          v-model="goodsListFilter.category"-->
      <!--          placeholder="请选择分类"-->
      <!--          filterable-->
      <!--          clearable-->
      <!--        >-->
      <!--          <el-option-->
      <!--            v-for="item in categoryList"-->
      <!--            :key="item.value"-->
      <!--            :label="item.label"-->
      <!--            :value="item.value"-->
      <!--          ></el-option>-->
      <!--        </el-select>-->
      <!--      </div>-->
      <div class="fl pb5 pr5 pt5">
        <el-button
          type="primary"
          @click="getGoodsList">
          检索
        </el-button>
      </div>
      <div class="fr">
        <el-button type="primary" @click="newGoods">追加</el-button>
      </div>
    </div>
    <div class="home-left">
      <Empty v-if="goodsList.length === 0"></Empty>
      <el-table
        :data="goodsList"
        class="home-list"
        style="width: 100%"
        v-else>
        <el-table-column
          label="ID"
          prop="id"
          width="80">
        </el-table-column>
        <el-table-column
          prop="images"
          label="图片"
          width="150">
          <template #default="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.images.length>0 ? scope.row.images[0].cos_path : '' "
              :preview-teleported="true"
              fit="contain"
              :preview-src-list="scope.row.images.map(item => item.cos_path)">
              <template #error>
                <el-icon class="image-slot">
                  <Picture/>
                </el-icon>
              </template>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="商品名称">
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template #default="scope">
            <el-button
              type="primary"
              link
              @click="editGoods(scope.row)">
              编辑
            </el-button>
            <el-button
              type="primary"
              link
              @click="openGoodsImageDialog(scope.row)">
              图片管理
            </el-button>
            <el-button
              type="danger"
              link
              @click="deleteGoods(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="clearfix pt20 pb20">
      <el-pagination
        class="fr"
        background
        layout="prev, pager, next, sizes, total"
        :total="goodsListFilter.totalSize"
        @current-change="getGoodsList"
        @size-change="getGoodsList(true)"
        v-model:current-page="goodsListFilter.page"
        v-model:page-size="goodsListFilter.size"
        :page-sizes="[10, 20, 50, 100]"
      />
    </div>
    <el-dialog v-model="goodsFormRef" :title="goodsForm.id === null ? '新增':'修改'" width="30%" destroy-on-close
               draggable>
      <span v-loading="loading">
        <el-form
          ref="goodsRuleFormRef"
          :model="goodsForm"
          :rules="goodsRules"
          label-width="120px"
          class="home-form"
        >
        <el-form-item label="商品条码" prop="jan_code">
          <el-input v-model="goodsForm.jan_code" :disabled="goodsForm.id !== null"></el-input>
        </el-form-item>
        <el-form-item label="商品名称" prop="name">
          <el-input v-model="goodsForm.name"></el-input>
        </el-form-item>
        <el-form-item label="商品描述" prop="description">
          <el-input type="textarea" rows="5" v-model="goodsForm.description"></el-input>
        </el-form-item>
          <!--        <el-form-item label="商品分类" prop="category_code">-->
          <!--           <el-select-->
          <!--             v-model="goodsForm.category_code"-->
          <!--             placeholder="请选择分类"-->
          <!--             filterable-->
          <!--             clearable-->
          <!--           >-->
          <!--              <el-option-->
          <!--                v-for="item in categoryList"-->
          <!--                :key="item.value"-->
          <!--                :label="item.label"-->
          <!--                :value="item.value"-->
          <!--              ></el-option>-->
          <!--            </el-select>-->
          <!--        </el-form-item>-->
        </el-form></span>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="goodsFormRef = false">取消</el-button>
        <el-button type="primary" @click="createGoods">
          {{ goodsForm.id === null ? '新增' : '编辑' }}
        </el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog v-model="goodsImageDialog" title="图片管理" width="70%" destroy-on-close align-center
               class="no-padding">

      <div class="home-right custom-upload" v-loading="loading">
        <el-upload
          v-model:file-list="goodsImageList"
          :action="'/api/admin/goods/' + goods.id + '/image'"
          list-type="picture-card"
          :before-remove="deleteGoodsImage"
          :limit="10"
          accept="image/*"
          :http-request="uploadFile"
          :before-upload="beforeUploadImage"
          :on-exceed="handleUploadExceed">
          <el-icon>
            <Plus/>
          </el-icon>
          <template #tip>
            <div class="el-upload__tip">
              jpg/jpeg/png 最大5MB 最多上传10张图片
            </div>
          </template>
        </el-upload>
      </div>
    </el-dialog>
    <div class="none">
      <a ref="downloadRef"></a>
    </div>
  </div>
</template>

<script>
import {reactive, ref} from 'vue'
import {dayjs, ElMessage, ElMessageBox} from 'element-plus'
import {authApi} from '@/api'
import {onMounted} from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
// @ is an alias to /src

export default {
  name: 'Home',
  components: {Empty},
  setup() {

    // category list
    const categoryList = ref([])
    const category = ref(null)
    const getCategoryList = () => {
      authApi.getCategoryList().then(res => {
        categoryList.value = res.data.map(item => {
          return {
            label: item.name,
            value: item.code
          }
        })
      })
    }

    // goods form

    const goodsList = ref([])
    const goods = ref(null)
    const goodsFormRaw = reactive({
      id: null,
      name: null,
      description: null,
      jan_code: null,
      category_code: null,
    })
    const goodsForm = reactive(JSON.parse(JSON.stringify(goodsFormRaw)))
    const goodsFormRef = ref(false)

    const goodsRuleFormRef = ref(null)

    const goodsRules = reactive({
      name: [
        {required: true, message: '请输入商品名称', trigger: 'blur'},
      ],
      description: [
        {required: true, message: '请输入商品描述', trigger: 'blur'},
      ],
      jan_code: [
        {required: true, message: '请输入商品条码', trigger: 'blur'},
      ],
    })

    const goodsListFilter = reactive({
      page: 1,
      size: 20,
      totalSize: 0,
      keyword: null,
      category: null,
    })
    const getGoodsList = (reset) => {
      if (reset === true) {
        goodsListFilter.page = 1
      }
      if (goodsListFilter.category === '') {
        goodsListFilter.category = null
      }
      loading.value = true
      authApi.getGoodsList(goodsListFilter).then(res => {
        goodsList.value = res.data.list
        goodsListFilter.totalSize = res.data.total
      }).finally(() => {
        loading.value = false
      })
    }
    const newGoods = () => {
      Object.keys(goodsFormRaw).forEach(key => {
        goodsForm[key] = goodsFormRaw[key]
      })
      goodsFormRef.value = true
    }
    const editGoods = (item) => {
      console.log(item)
      Object.keys(goodsFormRaw).forEach(key => {
        goodsForm[key] = item[key]
      })
      goodsFormRef.value = true
    }
    const deleteGoods = (item) => {
      ElMessageBox.confirm(
        '确定要删除吗?',
        '删除商品',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'error',
        }
      ).then(() => {
        loading.value = true
        authApi.deleteGoods(item.id).then(res => {
          console.log(res)
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
          getGoodsList()
        }).finally(() => {
          loading.value = false
        })
      })

    }
    const createGoods = () => {
      goodsRuleFormRef.value.validate((valid) => {
        if (valid) {
          loading.value = true
          if (goodsForm.id === null) {
            authApi.createGoods(goodsForm).then(res => {
              console.log(res)
              ElMessage({
                message: '创建成功',
                type: 'success',
              })
              getGoodsList()
              goodsFormRef.value = false
            }).finally(() => {
              loading.value = false
            })
          } else {
            authApi.updateGoods(goodsForm.id, goodsForm).then(res => {
              console.log(res)
              ElMessage({
                message: '编辑成功',
                type: 'success',
              })
              getGoodsList()
              goodsFormRef.value = false
            }).finally(() => {
              loading.value = false
            })
          }
        }
      })
    }

    const loading = ref(false)

    const dateFormat = (row, column, cellValue) => {
      return dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss')
    }

    // goods image
    const goodsImageList = ref([])
    const goodsImageDialog = ref(false)
    const goodsImageRuleFormRef = ref(null)
    const goodsImageRules = reactive({
      image_file: [
        {required: true, message: '请输入商品名称', trigger: 'blur'},
      ],
    })
    const openGoodsImageDialog = (item) => {
      goods.value = item
      authApi.getGoodsDetail(item.id).then(res => {
        console.log(res)
        goodsImageList.value = res.data.images.map(item => {
          return {
            id: item.id,
            url: item.cos_path,
            name: item.cos_path,
          }
        })
        goodsImageDialog.value = true
      })
    }
    const deleteGoodsImage = (uploadFile, uploadFiles) => {
      return new Promise((resolve, reject) => {
        ElMessageBox.confirm(
          '确定要删除吗?',
          '删除图片',
          {
            confirmButtonText: '删除',
            cancelButtonText: '取消',
            type: 'error',
          }
        ).then(() => {
          loading.value = true
          authApi.deleteGoodsImage(goods.value.id, uploadFile.id).then(res => {
            console.log(res)
            ElMessage({
              message: '删除成功',
              type: 'success',
            })
            resolve()
          }).finally(() => {
            loading.value = false
          })
        })
      })
    }

    const uploadFile = (param) => {
      return new Promise((resolve, reject) => {
        const fd = new FormData()
        fd.append('image_file', param.file)
        authApi.createGoodsImage(goods.value.id, fd).then(res => {
          console.log(res)
          ElMessage({
            message: '上传成功',
            type: 'success',
          })
          resolve(res.data)
        }).finally(() => {
          loading.value = false
        })
      })
    }

    const beforeUploadImage = (file) => {
      const isPic = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isPic) {
        ElMessage.error('上传的图片只能是 JPG/PNG 格式!')
      }
      if (!isLt5M) {
        ElMessage.error('上传头像图片大小不能超过 5MB!')
      }
      return isPic && isLt5M
    }

    const handleUploadExceed = (files, uploadFiles) => {
      ElMessage.warning(
        `最多上传10张图片`
      )
    }

    onMounted(() => {
      getCategoryList()
      getGoodsList()
    })
    return {
      loading,
      //
      categoryList,
      getCategoryList,
      //goods
      goodsList,
      goods,
      goodsForm,
      goodsFormRef,
      newGoods,
      createGoods,
      goodsRuleFormRef,
      goodsRules,
      editGoods,
      deleteGoods,
      getGoodsList,
      dateFormat,
      goodsListFilter,

      //goods image
      goodsImageList,
      goodsImageDialog,
      goodsImageRuleFormRef,
      goodsImageRules,
      openGoodsImageDialog,
      deleteGoodsImage,
      beforeUploadImage,
      handleUploadExceed,
      uploadFile,
    }
  }
}
</script>
<style scoped>
</style>

<style>
.custom-upload .el-upload-list .el-upload-list__item .el-upload-list__item-actions .el-upload-list__item-preview {
  display: none !important; /* 隐藏预览按钮 */
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 23px;
}
</style>
